import { ApiGet_ApplicationType } from "api/endpoints/get/application/getApplicationEndpoint";
import { ApplicationStepTaskStatus } from "components/application/TaskTypes";

type StepSummary = {
  borrowerDescription: string;
  id: ID;
  lenderDescription: string;
  modifiedAt: Date;
  name: string;
};

export type ApiPost_AdvanceApplicationStepResponse = {
  application: ApiGet_ApplicationType;
  applicationId: ID;
  id: ID;
  modifiedAt: Date;
  order: Array<number>;
  startedAt: Date;
  status: ApplicationStepTaskStatus;
  step?: StepSummary;
  stepId: ID;
  stepName?: string;
  stepRevisionId: ID;
};

export type ApiPost_AdvanceApplicationStepBody = {
  applicationId: ID;
  nextAppStepId?: ID;
  nextStepId?: ID;
};

/**
 * @returns application/advance-step
 */
export default function getPostAdvanceApplicationStepEndpoint() {
  return "application/advance-step";
}
