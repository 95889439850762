import { redirect } from "react-router-dom";
import LoginRouteController from "routes/LoginPage/LoginRouteController";
import cookies from "./CookieUtils";
import queryClient from "./queryClient";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DEFAULT_STALE_TIME = (window as any).Cypress != null ? 0 : 500; // 500ms

type FetchParams = Parameters<typeof fetch>;

type Endpoint = string;
export enum ErrorTypes {
  EXPIRED_TOKEN = "Token Expired",
  UNAUTHENTICATED = "Unauthenticated",
  UNAUTHORIZED = "Unauthorized",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ClientConfig<TBody = any> = {
  body?: TBody;
  headers?: HeadersInit;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  queryKey?: string;
  staleTime?: number;
};

export type Config = {
  body?: string;
  staleTime?: number;
} & Omit<ClientConfig, "body">;

const DEFAULT_CONFIG: Config = {
  headers: {},
  method: "GET",
  staleTime: DEFAULT_STALE_TIME,
};

export function removeTokenAndNavigate() {
  cookies.deleteCookie("user");
  cookies.deleteCookie("token");
  cookies.deleteCookie("knockToken");
  cookies.deleteCookie("refreshToken");
  window.location.replace("/");
  return redirect(LoginRouteController.getRoutePath());
}

function removeToken() {
  cookies.deleteCookie("user");
  cookies.deleteCookie("token");
  cookies.deleteCookie("knockToken");
  cookies.deleteCookie("refreshToken");
}

let isRefreshing = false;
// taken from this post: https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const client = <TData = any>(
  endpoint: string | Endpoint,
  clientConfig?: ClientConfig
): Promise<TData> => {
  async function genFetch(
    input: FetchParams[0],
    init?: FetchParams[1] & { queryKey?: string; staleTime?: number }
  ): Promise<Response> {
    const {
      queryKey: queryKeyInit = "",
      staleTime = DEFAULT_STALE_TIME,
      ...rest
    } = init ?? {};

    const queryKey = endpoint + rest.method + queryKeyInit;
    const resp = await queryClient.fetchQuery(
      queryKey,
      async () => await fetch(input, rest),
      {
        staleTime,
      }
    );
    return resp;
  }

  const loginPath = LoginRouteController.getRoutePath();
  const currentPath = window.location.pathname;

  return new Promise((resolve, _reject) => {
    const { body, ...customConfig } = clientConfig ?? DEFAULT_CONFIG;
    const URL = process.env.REACT_APP_API_BASE_URL;
    const token = cookies.getCookie("token") ?? null;
    const refreshToken = cookies.getCookie("refreshToken") ?? null;
    const headers: HeadersInit = { "content-type": "application/json" };
    if (token !== null) {
      headers.Authorization = `Bearer ${token}`;
    }
    const config: Config = {
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    };

    if (body != null) {
      config.body = JSON.stringify(body);
    }

    resolve(
      genFetch(`${URL}${endpoint}`, config).then(async (response) => {
        const status = await response.status;
        if (status === 401) {
          if (currentPath.startsWith(loginPath)) {
            return removeToken();
          }
          const errorText = await response.clone().text();
          const error = await JSON.parse(errorText);
          if (error.status === ErrorTypes.EXPIRED_TOKEN) {
            if (!isRefreshing) {
              isRefreshing = true;
              const res = await genFetch(`${URL}auth/refresh`, {
                body: JSON.stringify({ refreshToken }),
                headers: {
                  Authorization: `Bearer ${token}`,
                  "content-type": "application/json",
                },
                method: "POST",
              });
              const results = await res.clone().json();
              if (results.status === "success") {
                headers.Authorization = `Bearer ${results.data.token}`;
                cookies.setCookie("token", results.data.token);
                cookies.setCookie("refreshToken", results.data.refreshToken);
                cookies.setCookie("knockToken", results.data.knockToken);
                isRefreshing = false;
                return client(endpoint, { body, ...customConfig, headers });
              }
              // token is invalid, remove it and redirect to login.
              // This may not be necessary; however, in case of situations where the token is invalid but the user is still logged in,
              // this will clean up the cookies and force the user to log in again.
              return removeTokenAndNavigate();
            }
            return "Refreshing";
          }
          removeTokenAndNavigate();
        }

        let text = "";
        let clone = response.clone();
        try {
          text = await clone.text();
        } catch (error) {
          clone = clone.clone();
          text = await clone.text();
        }

        // eslint-disable-next-line no-useless-catch
        try {
          const data = text ? JSON.parse(text) : null;
          return data;
        } catch (error) {
          throw error;
        }
      })
    );
  });
};

export default client;

/*
Example Usage

async/await:
try {
    const data = await client(url); //url is really path
    //do something with data
}
catch(error) {
    console.log('error getting data', error);
}

Promise syntax
ApiClient(path)
    .then((result) => doSomethingWithResult(result))
    .catch((e) => console.log(e))
    .finally(() => doneWithApiCallStuff());

*/
