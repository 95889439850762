import { User } from "api/endpoints/auth/login";

export type ApiGet_UserCurrentUserResponse = {
  user: User | null;
};

/**
 *
 * @returns "user/current-user"
 */
export default function getCurrentUserEndpoint() {
  return "user/current-user";
}
