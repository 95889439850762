import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import useMutation from "hooks/useMutation";
import { User, ApiPost_AuthLoginResponse } from "api/endpoints/auth/login";
import getAuthRegisterEndpoint, {
  ApiPost_AuthRegisterResponse,
} from "api/endpoints/auth/register";
import { usePrestaCookies, Cookies } from "context/PrestaCookiesContext";
import CookieUtils from "utilities/CookieUtils";
import queryClient from "utilities/queryClient";
import { Config } from "utilities/apiClient";
import { CometChatUIKit } from "@cometchat/chat-uikit-react";
import ViewerContext from "./ViewerContext";

type Props = {
  children: JSX.Element;
};

type UserContextV2Value = {
  cookies_DEPRECATED_DO_NOT_USE: Cookies;
  getViewerContext: () => ViewerContext;
  logout(): void;
  register: (
    firstName: string,
    lastName: string,
    username: string,
    password: string,
    inviteCode: string,
    phoneNumber: string
  ) => Promise<ApiPayload<ApiPost_AuthLoginResponse>>;
  setViewerContext: React.Dispatch<React.SetStateAction<ViewerContext>>;
  viewerContext: ViewerContext;
};

export const UserContextV2 = createContext<UserContextV2Value | undefined>(
  undefined
);

export function useUserContextV2() {
  const context = useContext(UserContextV2);
  if (context == null) {
    throw new Error("UserContextV2 must be used within its provider");
  }
  return context;
}

const GET_CONFIG: Config = { method: "GET" };

/**
 * The only reason this is V2 is because the original has not yet been deleted.
 * Otherwise, this is the primary provider used to handle User Context.
 */
export default function UserContextV2Provider({ children }: Props) {
  const { cookies, setCookies } = usePrestaCookies();

  const [viewerContext, setViewerContext] = useState<ViewerContext>(() => {
    let user: User | null = CookieUtils.getCookie("user") as User;
    if (!Boolean(user)) {
      user = null;
    }
    return new ViewerContext(user);
  });

  const [commitPost] = useMutation();
  const [commitGet] = useMutation(GET_CONFIG);

  const setCookiesImpl = useCallback(
    function setCookiesImpl(
      token: string,
      refreshToken: string,
      user: User | null,
      knockToken: string
    ) {
      const vc = new ViewerContext(user);
      setViewerContext(vc);
      const config = {
        maxAge: 3600,
        path: "/",
      };
      setCookies([
        { config, name: "token", value: token },
        {
          config,
          name: "refreshToken",
          value: refreshToken,
        },
        {
          config,
          name: "user",
          value: user,
        },
        {
          config,
          name: "knockToken",
          value: knockToken,
        },
      ]);
    },
    [setCookies]
  );

  const genRegister = useCallback(
    function genRegister(
      firstName: string,
      lastName: string,
      username: string,
      password: string,
      inviteCode: string,
      phoneNumber: string
    ) {
      return new Promise<ApiPayload<ApiPost_AuthLoginResponse>>((resolve) => {
        const body = {
          firstName,
          inviteCode,
          lastName,
          password,
          phoneNumber,
          username,
        };
        commitPost<ApiPost_AuthRegisterResponse, typeof body>({
          body,
          endpoint: getAuthRegisterEndpoint(),
          onCompleted(response) {
            if (response.status === "success") {
              const { data } = response;
              const { knockToken, refreshToken, token, user } = data;
              setCookiesImpl(token, refreshToken, user, knockToken);
            }
            resolve(response);
          },
        });
      });
    },
    [commitPost, setCookiesImpl]
  );

  const logout = useCallback(
    function logout() {
      queryClient.invalidateQueries();
      ["user", "token", "knockToken", "refreshToken"].forEach((token) => {
        CookieUtils.deleteCookie(token);
      });
      setCookiesImpl("", "", null, "");
      commitGet({
        endpoint: "auth/logout",
        onCompleted() {
          setViewerContext(new ViewerContext());
          setTimeout(() => {
            window.location.replace("/");
          }, 0);
          CometChatUIKit.logout();
        },
      });
    },
    [commitGet, setCookiesImpl]
  );

  const getViewerContext = useCallback(
    function getViewerContext() {
      return viewerContext;
    },
    [viewerContext]
  );

  const memoValue = useMemo(
    () => ({
      // TODO delete from context once all implementations are removed
      cookies_DEPRECATED_DO_NOT_USE: cookies,
      getViewerContext,
      logout,
      register: genRegister,
      setViewerContext,
      viewerContext,
    }),
    [
      cookies,
      genRegister,
      logout,
      getViewerContext,
      viewerContext,
      setViewerContext,
    ]
  );

  return (
    <UserContextV2.Provider value={memoValue}>
      {children}
    </UserContextV2.Provider>
  );
}
