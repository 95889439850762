import { useCallback, useEffect, useMemo, useState } from "react";
import client from "utilities/apiClient";
import queryClient from "utilities/queryClient";

// TODO - Create endpoint enum or union type
type Endpoint = string;

type UseGetConfig = {
  isLazy?: boolean; // Does not fetch upon mount. Instead, use `lazyFetch` to declaratively fetch
  loadOnRefetch?: boolean; // When refetching, it does not change the loading state
  staleTime?: number;
};

/**
 *
 * Returns the data and loading states for a given endpoint.
 * Also provides options to lazily load the data.
 *
 * @example
 * ```jsx
 * type Data = {
 *   title: string;
 * }
 *
 * const {data, isLoading} = useGet<Data>(ENDPOINT);
 *
 *
 * if (isLoading) {
 *  return <Loader />
 * }
 *
 * return <div>{data.title}</div>
 * ```
 *
 * @param endpoint The endpoint which you need to fetch data
 * @param config Internal configuration for this hook
 * @returns
 */
export default function useGet<TData>(
  endpoint: string | Endpoint,
  config: UseGetConfig = {}
) {
  const { isLazy = false, loadOnRefetch = true } = config;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TData | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const genData = useCallback(
    async (maybeEndpoint?: string) => {
      return await client<ApiPayload<TData>>(maybeEndpoint ?? endpoint, {
        staleTime: config.staleTime,
      });
    },
    [config.staleTime, endpoint]
  );

  useEffect(() => {
    if (!isLazy) {
      setIsLoading(true);
      genData()
        .then((resp) => {
          if (resp.status === "failure") {
            setError(() => {
              throw new Error(resp.response);
            });
          }
          setData(resp.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(() => {
            throw new Error(err);
          });
        });
    }
  }, [genData, isLazy, endpoint, loadOnRefetch]);

  /**
   *
   * @returns Lazily fetches from the endpoint
   */
  const lazyFetch = useCallback(
    async function lazyFetch(maybeEndpoint?: string) {
      queryClient.invalidateQueries(maybeEndpoint ?? endpoint);
      if (loadOnRefetch) {
        setIsLoading(true);
      }
      const resp = await genData(maybeEndpoint);
      setData(resp.data);
      setIsLoading(false);
      return resp.data;
    },
    [endpoint, genData, loadOnRefetch]
  );

  const refetch = useCallback(
    async function refetch() {
      queryClient.invalidateQueries(endpoint);
      return await lazyFetch(endpoint);
    },
    [endpoint, lazyFetch]
  );

  return useMemo(
    () => ({
      data,
      error,
      isLoading,
      lazyFetch,
      refetch,
    }),
    [data, error, isLoading, lazyFetch, refetch]
  );
}
