import * as React from "react";
import { useEffect } from "react";
import Hotjar from "@hotjar/browser";
import "./App.css";
import AppProvider from "context/AppProvider";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";
import ErrorBoundaryDefaultFallback from "components/errorBoundary/ErrorBoundaryDefaultFallback";
import useCheatCode from "hooks/useCheatCode";
import CacheBuster from "react-cache-buster";
import {
  CometChatUIKit,
  UIKitSettingsBuilder,
} from "@cometchat/chat-uikit-react";
import packageInfo from "../package.json";

const AppContent = React.lazy(() => import("./AppContent"));

const hotJarSiteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
const hotjarVersion = Number(process.env.REACT_APP_HOTJAR_VERSION);

const COMETCHAT_CONSTANTS = {
  APP_ID: process.env.REACT_APP_COMETCHAT_APPID,
  AUTH_KEY: process.env.REACT_APP_COMETCHAT_AUTH_KEY,
  REGION: process.env.REACT_APP_COMETCHAT_REGION,
};

if (
  COMETCHAT_CONSTANTS.APP_ID !== undefined &&
  COMETCHAT_CONSTANTS.AUTH_KEY !== undefined &&
  COMETCHAT_CONSTANTS.REGION !== undefined
) {
  const UIKitSettings = new UIKitSettingsBuilder()
    .setAppId(COMETCHAT_CONSTANTS.APP_ID)
    .setRegion(COMETCHAT_CONSTANTS.REGION)
    .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
    .subscribePresenceForAllUsers()
    .build();

  // Initialize CometChat UI Kit
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  CometChatUIKit.init(UIKitSettings)!;
}

function App() {
  useCheatCode();
  useEffect(() => {
    Hotjar.init(hotJarSiteId, hotjarVersion, {
      debug: process.env.NODE_ENV === "development",
    });
  }, []);

  const { version } = packageInfo;

  const isCacheBusterEnabled =
    process.env.NODE_ENV === "production" ||
    process.env.NODE_ENV === "staging" ||
    process.env.NODE_ENV === "demo";

  return (
    // TODO - add fallback
    <CacheBuster
      currentVersion={version}
      isEnabled={isCacheBusterEnabled}
      isVerboseMode={false}
    >
      <React.Suspense fallback={null}>
        <AppProvider>
          <ErrorBoundary
            componentName={App.name}
            fallback={<ErrorBoundaryDefaultFallback />}
          >
            <AppContent />
          </ErrorBoundary>
        </AppProvider>
      </React.Suspense>
    </CacheBuster>
  );
}

export default App;
