export enum TaskTypes {
  AMORTIZATION = "amortization",
  CHECKLIST = "checklist",
  CONTRACT = "contract",
  CREDIT_MEMO = "credit_memo",
  DOCUMENT = "document",
  DOWNLOAD = "download",
  RESOURCE = "resource",
  RUBRIC = "rubric",
  SPREADSHEET = "spreadsheet",
}

export enum ApplicationStepTaskStatus {
  COMPLETE = "Complete",
  IN_PROGRESS = "In Progress",
  NOT_STARTED = "Not Started",
  PENDING_REVIEW = "Pending Review",
}
