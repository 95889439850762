import joinPaths from "routes/AppRoutes/joinPaths";
import { CapitalizedUserTypes } from "types/UserTypes";
import { ApiGet_ApplicationCurrentStepData } from "./getApplicationCurrentStepEndpoint";

export type ApiGet_ApplicationAllStepsData =
  Array<ApiGet_ApplicationCurrentStepData>;

/**
 * @param applicationId
 * @param assignee
 * @returns "application/{applicationId}/all-steps?assignee=:assignee"
 */
export default function getApplicationAllStepsEndpoint(
  applicationId: ID,
  assignee?: CapitalizedUserTypes
) {
  const assigneeQueryParam = assignee != null ? `?assignee=${assignee}` : "";
  return joinPaths(
    "application",
    applicationId,
    "all-steps",
    assigneeQueryParam
  );
}
