import * as React from "react";
import {
  Chip,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { StylesInput } from "utilities/stylex";
import { useUserContextV2 } from "context/UserContextV2";
import { StepOption } from "./ApplicationStepOverviewAdvanceStepFooter";

type Props = {
  option: StepOption;
  selectedStep: StepOption | null;
  setSelectedStep: (option: StepOption) => void;
};

export default function ApplicationStepOverviewNextStepOption({
  option,
  selectedStep,
  setSelectedStep,
}: Props) {
  function getSelectedOptionStyling(): StylesInput {
    return {
      border: (theme) => {
        const color =
          selectedStep?.value === option.value
            ? theme.palette.primary.main
            : theme.palette.divider;
        return `1px solid ${color}`;
      },
    };
  }

  const { viewerContext } = useUserContextV2();
  const user = viewerContext.getUserX();
  const hasDisbursePermissions = user.permissions?.some(
    (permission) =>
      permission.name === "LOAN_PRODUCT_DISBURSE" &&
      permission.isEnabled === true
  );

  const isOptionDisabled =
    !Boolean(hasDisbursePermissions) && option.label === "Booking";

  if (isOptionDisabled) {
    return (
      <Tooltip title="You do not have permission to move this application to booking.">
        <ListItem
          sx={{
            ...getSelectedOptionStyling(),
            borderRadius: "5px",
            mt: 1,
            p: 0,
          }}
        >
          <ListItemButton
            disabled={isOptionDisabled}
            onClick={() => setSelectedStep(option)}
            sx={{ p: 1 }}
          >
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography>{option.label}</Typography>
              <ListItemSecondaryAction>
                <Chip label={option.status} size="small" variant="outlined" />
              </ListItemSecondaryAction>
            </Stack>
          </ListItemButton>
        </ListItem>
      </Tooltip>
    );
  }

  return (
    <ListItem
      sx={{ ...getSelectedOptionStyling(), borderRadius: "5px", mt: 1, p: 0 }}
    >
      <ListItemButton onClick={() => setSelectedStep(option)} sx={{ p: 1 }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography>{option.label}</Typography>
          <ListItemSecondaryAction>
            <Chip label={option.status} size="small" variant="outlined" />
          </ListItemSecondaryAction>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
}
