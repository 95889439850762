import joinPaths from "routes/AppRoutes/joinPaths";
import { ApplicationDocumentStatus } from "components/styles/ApplicationDocumentStatusColors";
import { ApplicationStepTaskStatus } from "components/application/TaskTypes";
import { Resource } from "types/ResourceType";
import { CapitalizedUserTypes } from "types/UserTypes";
import { User } from "api/endpoints/auth/login";
import {
  ApiApplicationCreditMemoSummary,
  ApiApplicationSheetSummary,
  ApiBookedLoanBorrowerSummary,
  StepStaticType,
  StepType,
} from "@presta-technologies-inc/presta-types";
import { ApiDocument } from "../task/getTaskByIdEndpoint";

export type ApiApplicationCurrentStepApplication = {
  borrowerId: ID;
  completedAt: string | null;
  createdAt: string;
  createdByUserId: number;
  deletedAt: string | null;
  id: ID;
  lenderId: ID;
  loanProductId: ID;
  modifiedAt: string;
  startedAt: string;
  workflowId: ID;
};

export type ApplicationContractTask = {
  applicationContractId: ID;
  id: ID;
  status: ApplicationDocumentStatus;
  title: string;
  type: CapitalizedUserTypes;
};

export type ApplicationDocumentTask = {
  document: {
    [key: string]: unknown;
    title: string;
  };
  icon: string;
  id: ID;
  isRequired?: boolean;
  link: string;
  status?: ApplicationDocumentStatus;
  title: string;
};

export type ApplicationRubricTask = {
  applicationRubricId: string;
  isRequired: boolean;
  status: ApplicationDocumentStatus;
  title: string;
};

export type ApiRubric = {
  applicationRubricId: ID;
  isRequired: boolean;
  status: ApplicationDocumentStatus;
  title: string;
};

export type ApiApplicationChecklist = {
  applicationChecklistId: ID;
  status: ApplicationDocumentStatus;
  title: string;
};

export type ApiApplicationTaskAmortization = {
  id: ID;
  status: ApplicationDocumentStatus;
};

export type ApiTask = {
  amortization: boolean;
  applicationTaskAmortizations: Array<ApiApplicationTaskAmortization>;
  applicationTaskId: ID;
  assignee: CapitalizedUserTypes;
  checklists?: Array<ApiApplicationChecklist>;
  contracts: Array<ApplicationContractTask>;
  creditMemos?: Array<ApiApplicationCreditMemoSummary>;
  description: string;
  documents: Array<ApplicationDocumentTask | ApiDocument>;
  id: string;
  instructions: string | null;
  required: boolean;
  resources: Array<Resource>;
  rubrics: Array<ApplicationRubricTask | ApiRubric>;
  spreadsheets: Array<ApiApplicationSheetSummary>;
  status: ApplicationStepTaskStatus;
  title: string;
};

export type ApiApplicationCurrentStepApplicationTask = {
  amortization: boolean;
  applicationChecklists?: Array<ApiApplicationChecklist>;
  applicationCreditMemos?: Array<ApiApplicationCreditMemoSummary>;
  applicationId?: ID;
  applicationSheets: Array<ApiApplicationSheetSummary>;
  applicationStepEntityId?: ID;
  applicationStepId?: ID;
  applicationTaskAmortizations: Array<ApiApplicationTaskAmortization>;
  applicationTaskContracts: Array<ApplicationContractTask>;
  applicationTaskDocuments: Array<ApiDocument>;
  applicationTaskRubrics: Array<ApiRubric>;
  approvedByUserId: ID | null;
  definition?: {
    name: string;
    order: number;
  };
  id: ID;
  modifiedAt: string | null;
  startedAt: string | null;
  status: ApplicationStepTaskStatus;
  task: ApiTask;
  taskId: ID;
  taskRevisionId: ID;
};

export type ApiBorrowerOrganization = {
  generalContactEmail: string | null;
  id: ID;
  name: string;
  thumbnail: string | null;
};

type ApiApplicationCurrentStepTransition = {
  createdAt: string;
  createdByUserId: ID;
  deletedAt: string | null;
  entityId: ID;
  expiredAt: string | null;
  fromStepEntityId: ID;
  modifiedAt: string;
  revisionId: ID;
  toStepEntityId: ID;
};

export type ApiApplicationCurrentStepStep = {
  borrowerDescription: string | null;
  defaultLeadUserCode: string | null;
  entityId: ID;
  fromTransitions: Array<ApiApplicationCurrentStepTransition>;
  id: ID;
  lenderDescription: string | null;
  modifiedAt: string;
  name: string;
  position: {
    x: number;
    y: number;
  };
  resources: Array<Resource>;
};

export type ApiApplicationTargetStep = {
  applicationId: ID;
  id: ID;
  leadUserCode: string;
  stepId: ID;
  stepName: string;
  stepRevisionId: ID;
  stepSubType: StepStaticType | null;
};

export type ApiApplicationLeadUser = Pick<
  User,
  "code" | "email" | "firstName" | "lastName" | "permissions"
>;

export type ApiGet_ApplicationCurrentStepData = {
  application: ApiApplicationCurrentStepApplication;
  applicationId: ID;
  applicationTasks: Array<ApiApplicationCurrentStepApplicationTask>;
  bookedLoanSummary?: ApiBookedLoanBorrowerSummary;
  borrowerOrganization: ApiBorrowerOrganization;
  id: ID;
  isCurrentStep: boolean;
  leadUser?: ApiApplicationLeadUser | null;
  modifiedAt: string;
  rubrics: Array<ApiRubric>;
  startedAt: string;
  status: ApplicationStepTaskStatus;
  step: ApiApplicationCurrentStepStep;
  stepId: ID;
  stepName: string;
  stepRevisionId: ID;
  stepSubType: StepStaticType | null;
  stepType: StepType;
  targetApplicationSteps?: Array<ApiApplicationTargetStep>;
  targetSteps?: Array<ApiApplicationCurrentStepStep>;
};

/**
 * @param applicationId
 * @returns "application/{applicationId}/current-step"
 */
export default function getApplicationCurrentStepEndpoint(applicationId: ID) {
  return joinPaths("application", applicationId, "current-step");
}
