import EventEmitter from "events";

export enum EventEmitterEvents {
  AdvanceApplicationStep = "advanceApplicationStep",
  UpdateLoanProductList = "updateLoanProductList",
  UpdateWorkflowTask = "updateWorkflowTask",
}

/**
 * https://nodejs.org/en/learn/asynchronous-work/the-nodejs-event-emitter
 */
export default new EventEmitter();
