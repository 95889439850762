import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  List,
} from "@mui/material";
import getApplicationAllStepsEndpoint, {
  ApiGet_ApplicationAllStepsData,
} from "api/endpoints/get/application/getApplicationAllStepsEndpoint";
import getApplicationCurrentStepEndpoint, {
  ApiGet_ApplicationCurrentStepData,
} from "api/endpoints/get/application/getApplicationCurrentStepEndpoint";
import getPostAdvanceApplicationStepEndpoint, {
  ApiPost_AdvanceApplicationStepBody,
  ApiPost_AdvanceApplicationStepResponse,
} from "api/endpoints/post/application/getPostAdvanceApplicationStepEndpoint";
import { ApplicationStepTaskStatus } from "components/application/TaskTypes";
import { useSnackbar } from "context/SnackbarContext";
import useGet from "hooks/useGet";
import useMutation from "hooks/useMutation";
import { useEffect, useState } from "react";
import ApplicationStepOverviewNextStepOption from "routes/ApplicationPage/ApplicationStepOverview/ApplicationStepOverviewNextStepOption";
import EventEmitter, { EventEmitterEvents } from "utilities/EventEmitter";
import only from "utilities/only";

type Props = {
  applicationId: ID;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?(): void;
};

export default function ApplicationsUpdateStepModal({
  applicationId,
  isOpen,
  onClose,
  onSuccess,
}: Props) {
  const { data: allStepsDetailData, lazyFetch: lazyFetchAllStepsDetail } =
    useGet<ApiGet_ApplicationAllStepsData>(
      getApplicationAllStepsEndpoint(applicationId),
      { isLazy: true }
    );

  const { data: applicationStep, lazyFetch: lazyFetchApplicationStep } =
    useGet<ApiGet_ApplicationCurrentStepData>(
      getApplicationCurrentStepEndpoint(applicationId),
      {
        isLazy: true,
      }
    );

  const uncompletedTasks = applicationStep?.applicationTasks.filter(
    (task) =>
      task.status !== ApplicationStepTaskStatus.COMPLETE && task.task.required
  );

  useEffect(() => {
    if (isOpen) {
      lazyFetchAllStepsDetail();
      lazyFetchApplicationStep();
    }
  }, [isOpen, lazyFetchAllStepsDetail, lazyFetchApplicationStep]);

  const stepOptions = allStepsDetailData?.map(
    (step: ApiGet_ApplicationCurrentStepData) => {
      return {
        label: step.stepName,
        status: step.status,
        value: step.id,
      };
    }
  );

  const [selectedStep, setSelectedStep] = useState(() => {
    if (stepOptions?.length === 1) {
      return only(stepOptions);
    }
    return null;
  });
  const { openSnackbar } = useSnackbar();
  const [commitPost] = useMutation();

  function renderIncompleteStepsWarning() {
    if (uncompletedTasks?.length === 1) {
      return (
        <Typography component="span" fontWeight="bold">
          There is still 1 incomplete required task in this step.{" "}
        </Typography>
      );
    }
    if ((uncompletedTasks?.length ?? 0) > 0) {
      return (
        <Typography component="span" fontWeight="bold">
          There are still {uncompletedTasks?.length} incomplete required tasks
          in this step.{" "}
        </Typography>
      );
    }
    return null;
  }

  const renderedStep = (
    <List>
      {stepOptions?.map((nextStepOption) => {
        return (
          <ApplicationStepOverviewNextStepOption
            key={nextStepOption.value}
            option={nextStepOption}
            selectedStep={selectedStep}
            setSelectedStep={setSelectedStep}
          />
        );
      })}
    </List>
  );

  function renderStepOptions() {
    if (stepOptions != null) {
      return (
        <Box>
          <Typography>Select step</Typography>
          {renderedStep}
        </Box>
      );
    }
    return null;
  }

  function advanceStepClick() {
    if (selectedStep?.value == null) {
      throw new Error(
        "Selected step must be defined to advance application step"
      );
    }
    const body: ApiPost_AdvanceApplicationStepBody = {
      applicationId,
    };

    body.nextAppStepId = selectedStep.value;
    const nextStep = allStepsDetailData?.find((appStep) => {
      return appStep.id === selectedStep.value;
    });
    body.nextStepId = nextStep?.stepId;

    commitPost<
      ApiPost_AdvanceApplicationStepResponse,
      ApiPost_AdvanceApplicationStepBody
    >({
      body,
      endpoint: getPostAdvanceApplicationStepEndpoint(),
      onCompleted(response) {
        openSnackbar(
          `You advanced this business to ${response.data.stepName}.`
        );
        EventEmitter.emit(EventEmitterEvents.AdvanceApplicationStep);
        onSuccess?.();
        onClose();
      },
    });
  }

  return (
    <Dialog fullWidth onClose={onClose} open={isOpen}>
      <DialogTitle>Advance Step</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Typography>
            {renderIncompleteStepsWarning()}
            Are you sure you want to update this application to a different
            step?
          </Typography>
          {renderStepOptions()}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          disabled={selectedStep?.value == null}
          onClick={advanceStepClick}
          variant="contained"
        >
          UPDATE STEP
        </Button>
      </DialogActions>
    </Dialog>
  );
}
