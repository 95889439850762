import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { TableCell } from "components/mui/table";
import useBoolean from "hooks/useBoolean";
import { ApiGet_ApplicationType } from "api/endpoints/get/application/getApplicationEndpoint";
import ApplicationDeleteApplicationModal from "./ApplicationDeleteApplicationModal";
import ApplicationsUpdateStepModal from "./ApplicationsUpdateStepModal";

type Props = {
  applicationId: ID;
  refetchApplicationList: () => Promise<ApiGet_ApplicationType[]>;
};

export default function ApplicationTableRowButtonMenu({
  applicationId,
  refetchApplicationList,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    isTrue: isModalOpen,
    setFalse: closeModal,
    setTrue: openModal,
  } = useBoolean();

  const {
    isTrue: isUpdateStepModalOpen,
    setFalse: closeUpdateStepModal,
    setTrue: openUpdateStepModal,
  } = useBoolean();

  return (
    <TableCell
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        onClick={(e) => e.preventDefault()}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
      >
        <MenuItem
          onClick={() => {
            openUpdateStepModal();
            setAnchorEl(null);
          }}
        >
          Update Step
        </MenuItem>
        <Typography
          color="error"
          component={MenuItem}
          onClick={() => {
            openModal();
            setAnchorEl(null);
          }}
        >
          Delete
        </Typography>
      </Menu>
      <ApplicationDeleteApplicationModal
        applicationId={applicationId}
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        refetchApplicationList={refetchApplicationList}
      />
      <ApplicationsUpdateStepModal
        applicationId={applicationId}
        isOpen={isUpdateStepModalOpen}
        onClose={closeUpdateStepModal}
        onSuccess={() => {
          refetchApplicationList();
        }}
      />
    </TableCell>
  );
}
